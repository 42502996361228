import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';


// ----------------------------------------------------------------------


const initialState = {
    isLoading: false,
    error: null,
    current_shop: [] ,
    payments: null ,
    current_payment: '',
    current_paylink: null ,
    settings: {} ,
    onboarding: null ,
    shop: {} ,
    };


const slice = createSlice({
    name: 'shops',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setCurrentShop(state, action) {
            state.isLoading = false;
            state.current_shop = action.payload;
        },

        setPaymentsShop(state, action) {
            state.isLoading = false;
            state.payments = action.payload;
        },

        setSettingsShop(state, action) {
            state.isLoading = false;
            state.settings = action.payload;
        },

        setShop(state, action) {
            state.isLoading = false;
            state.shop = action.payload;
        },

        setCurrentPayment(state, action) {
            state.isLoading = false;
            state.current_payment = action.payload;
        },

        setCurrentPayLink(state, action) {
            state.isLoading = false;
            state.current_paylink = action.payload;
        },

        setOnBoarding(state, action) {
            state.isLoading = false;
            state.onboarding = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function getPayments(id , tg_id = null){
    // console.log("ID" , id);
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/payments/${id}?tg_id=${tg_id}`);

            // console.log(`axios response ${id}` , response.data);
            if(response.data.success === false) {
                dispatch(slice.actions.hasError(response.data.data));
            }

            dispatch(slice.actions.setPaymentsShop(response.data.data));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function getShopSettings(shopid){
    // https://api.notibot.ru/shops/{{shop_id}}/info
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/shops/${shopid}/info`);

            // console.log(`axios INFO ` , response.data);
            if(response.data.success === false) {
                dispatch(slice.actions.hasError(response.data.data));
            }

            dispatch(slice.actions.setSettingsShop(response.data.data));

        } catch (error) {
            dispatch(slice.actions.stopLoading());
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function getShopById(shopid  , act='onboarding'){
    // https://api.notibot.ru/shops/{{shop_id}}/info
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/shops/${shopid}`);

            if(act === 'onboarding'){
                if(response?.data) {
                    dispatch(slice.actions.setOnBoarding(response.data?.status));
                }else{
                    dispatch(slice.actions.hasError(response.data));
                }

            }else if(act === 'all'){
                dispatch(slice.actions.setShop(response.data));
            }


        } catch (error) {
            dispatch(slice.actions.stopLoading());
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function setOnboarding(shopid , element){
    // https://api.notibot.ru/shops/{{shop_id}}/info
    return async (dispatch) => {
        // dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/users/update-status?status=${element}`);

            // console.log(`axios INFO ` , response.data);
            if(response.data.success === false) {
                dispatch(slice.actions.hasError(response.data.data));
            }
            // dispatch(slice.actions.setSettingsShop(response.data.data));
        } catch (error) {
            // dispatch(slice.actions.stopLoading());
            dispatch(slice.actions.hasError(error));
        }
    }
}


export function setCurrentPayment(payment){
    // console.log("CLICK" , shop);
    return (dispatch) => dispatch(slice.actions.setCurrentPayment(payment));
}

export function setPayLink(link){
    // console.log("CLICK" , shop);
    return (dispatch) => dispatch(slice.actions.setCurrentPayLink(link));
}

export function setCurShop(shop){
    // console.log("CLICK" , shop);
    return (dispatch) => dispatch(slice.actions.setCurrentShop(shop));
}



