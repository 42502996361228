import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import {useRouter, useSearchParams} from 'src/routes/hooks';
//
import Box from "@mui/material/Box";
import { useAuthContext } from '../hooks';


const tg = window.Telegram.WebApp;
const tgData =  tg.initDataUnsafe;

const probka = {
  id: "111111" ,
  first_name: "Евгений" ,
  last_name: "Кудряшов",
  username: "jonnnnster",
  photo_url: "/img",
  auth_date: "12.12.2022",
  hash: "1234124reaedfadg3546qwer"
}
// ----------------------------------------------------------------------

const loginPaths = {
  jwt: paths.auth.jwt.login,
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const router = useRouter();

  const getParams = useSearchParams();

  const get_id = getParams.get('id');
  const get_first_name = getParams.get('first_name');
  const get_last_name = getParams.get('last_name');
  const get_username = getParams.get('username');
  const get_photo_url = getParams.get('photo_url');
  const get_auth_date = getParams.get('auth_date');
  const get_hash = getParams.get('hash');

  let AUTH_GET_HTTP = null;
  if(get_id && get_first_name
      && get_last_name
      && get_username
      && get_photo_url
      && get_auth_date
      && get_hash)
  {
    AUTH_GET_HTTP = {
      id: get_id ,
      first_name: get_first_name ,
      last_name: get_last_name,
      username: get_username,
      photo_url: get_photo_url,
      auth_date: get_auth_date,
      hash: get_hash
    }
  }

  const { authenticated, method , tgLogin } = useAuthContext();

  const [checked, setChecked] = useState(false);



  // ========= Логинимся ==========
  useEffect(() => {

    const authTg = async (data) => {
      try {
        // console.log("APP" , data);
        await tgLogin(data);

      } catch (error) {
        // console.error(error);
      }
    }

    // eslint-disable-next-line no-prototype-builtins
    const emptyTgObj = tgData.hasOwnProperty("user");
    // console.log("emptyTgObj" , emptyTgObj);
    let authdata = {};
    if(process.env.NODE_ENV !== 'production')authdata = probka;
    if(AUTH_GET_HTTP)authdata = AUTH_GET_HTTP;
    if(emptyTgObj)authdata = tgData;

    // tgLogin(authdata);
    authTg(authdata);

  } , [AUTH_GET_HTTP, tgLogin]);
  // ==============================

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      const loginPath = loginPaths[method];

      const href = `${loginPath}?${searchParams}`;

      router.replace(href);

    } else {
      setChecked(true);
    }
  }, [authenticated, method , router ]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (!check) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
