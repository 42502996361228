import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';


// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  curShopId: 0 ,
  tguserdata: null ,
  referrer: null ,
  location: null ,
  backBtn: {} ,
  chat: null ,
  utm: null ,
};

const slice = createSlice({
  name: 'startform',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // HAS ERROR

    setCurShopId(state, action){
      state.loading = false;
      state.curShopId = action.payload;
    } ,

    setTgUserData(state, action){
      state.loading = false;
      state.tguserdata = action.payload;
    } ,

    setLocation(state , action){
      if(state.location === null)state.location = action.payload;
      else if(state.location) {
        state.refferer = state.location;
        state.location = action.payload;
      }
    } ,
    setBackBtn(state, action){
      state.backBtn = action.payload;
    } ,
    setUtm(state, action){
      state.utm = action.payload;
    } ,
    setChat(state, action){
      state.chat = action.payload;
    } ,
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {setCurShopId, setTgUserData , setLocation, setBackBtn , setUtm ,  setChat} = slice.actions;


export function setUtmForUser(shopid , utm , tgid){

  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    // dispatch(slice.actions.resetDeleteItemStatus());
    try {
      const response = await axios.post(`/subscribers/${shopid}/${tgid}/set-utm` , {utm});
      // console.log("delete" , response);
      setUtm(utm)

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}
// ----------------------------------------------------------------------
// https://api.notibot.ru/subscribers/{{shop_id}}/{{user_id}}/set-utm


